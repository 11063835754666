<template>
    <div class="auction " :class="$route.query.isnew==1?'subchild-box':''"  ref="scrolling"  id="scrollbox" >
        <Head :title="$route.query.isnew==1?$t('mine.newer'):$t('common.auction')" :right="true" :show="$route.query.isnew==1">
            <img @click="gotoQuestion" :src="require('@/assets/imgs/rules.png')" class="rules-icon" alt="">
        </Head>
        <div class="main-box auction-box" v-if="cateproductList" >
            <div class="gold-box" v-if="$route.query.is_try_bonus=='1'">
                <p>
                    {{$t('wallet.try_gold_2')}}
                </p>
                <p class="psize">
                    {{tgold.usable_balance | int_format}} Bid Token
                </p>
            </div>
            <div class="auction-main" >
                <div class="auction-nav">
                    <div class="nav-txt">
                        {{$t('auction.fen_lan')}}
                    </div>
                    <div class="nav-slide">
                        <van-sidebar v-model="activeKey">
                            <van-sidebar-item :title="item.title" v-for="(item,i) in cateproductList" :key="i"/>
                        </van-sidebar>
                    </div>
                </div>
                <div class="nav-main" v-if="item.products">
                    <van-tabs :sticky="true" :animated="true" :swipeable="true" v-model="active" class="mytabs">
                        <van-tab title="Cloud Bid"  name="1" >
                            <AuctionList :timeendaction="timeEndAction" :isnew="$route.query.isnew==1" :cb="true" :tt="false" :item="cateproductList[activeKey]" :list="item.products.filter(v=>v.active_id==active)"></AuctionList>
                        </van-tab>
                        <van-tab title="Time Twister" name="2" >
                            <AuctionList :timeendaction="timeEndAction"  :isnew="$route.query.isnew==1"  :tt="true" :cb="false" :item="cateproductList[activeKey]" :list="item.products.filter(v=>v.active_id==active)"></AuctionList>
                        </van-tab>
                    </van-tabs>
                </div>
            </div>
        </div>
        <PlayRule ref="play" :changescroll="changescroll" ></PlayRule>

        <!-- 新手体验 弹框 -->
        <van-overlay class="g-overlay" :show="newshow">
            <div class="new-mask" @click.stop>
                <div class="top">
                  {{$t('news.news_user')}}
                </div>
                <p class="text">{{$t('news.news_tips')}}</p>

                <div class="mask-box"  v-if="user">
                        <van-cell :title="$t('info.user_real')"  >
                            <template #extra>
                                <p class="p-extra" v-if="user.is_kyc==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                <van-button v-else class="extra-btn" :class="lang=='cn'?'':'small-size'" @click="gotowhere('mine-realname')">
                                    {{ user.is_kyc ==3 ? $t('mine.kyc'+user.is_kyc) : $t('news.go_real')  }}
                                </van-button>
                                
                                <!-- <van-button v-else class="extra-btn" @click="gotowhere('mine-realname')">{{$t('news.go_real')}}</van-button> -->
                            </template>
                        </van-cell>
                        <van-cell :title="$t('news.bind_bank_card')">
                            <template #extra>
                                 <p class="p-extra" v-if="user.isbank==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                 <van-button v-else class="extra-btn" @click="gotowhere('mine-card')">{{$t('news.go_bind')}}</van-button>
                            </template>
                        </van-cell>
                        <van-cell :title="$t('info.add_address')" >
                            <template #extra >
                                <p class="p-extra" v-if="user.is_addr==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                 <van-button v-else class="extra-btn" @click="gotowhere('mine-address-add')">{{$t('news.go_bind')}}</van-button>
                            </template>
                        </van-cell>
                </div>
                <van-button class="btn" @click="newshow=false" >{{$t('login.sure')}}</van-button>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import {mapState,mapMutations} from "vuex"
import {myinfo} from "@/common"
import quickloginVue from '../../logins/quicklogin.vue';
export default {
    mixins:[myinfo],
    name:'main-auctionIndex',
    computed:{
        ...mapState(['jiqigoodList','homewinnerList']),
        activeKey:{
            get(){
                return this.$store.state.activeKey
            },
            set(v){
                this.$store.commit("changeactiveKey",v);
                this.item = this.cateproductList[v]
            }
        },
        active:{
            get(){
                return this.$store.state.active
            },
            set(v){
                this.$store.commit("changeactive",v);
            }
        }
    },
    watch:{
        homewinnerList(v){

            var obj = v[v.length-1]
            var data = {};
            if(obj['tt_winninginfo']){
                data = obj['tt_winninginfo']
            }else{
                data = obj['cb_winninginfo']
            }       
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    if(item.good_id==data.good_id&&item.qishu==data.qishu){
                        item = {...item,resultOut:true}
                    }
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        },
        jiqigoodList(v){
            var new_data = v[0];
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    
                    if(item.active_id==1){
                        // cb
                        if(new_data['buy_cb']){
                            if(new_data['buy_cb'].qishu==item.qishu&&new_data['buy_cb'].good_id==item.id){
                                // 推送了一条 cb 数据
                                item = {
                                    ...item,
                                    join_num:item.join_num*1 + new_data['buy_cb'].total*1 
                                }
                            }
                        }
                    }
                    if(item.active_id==2){
                        // tt 
                        if(new_data['buy_tt']){
                            if(new_data['buy_tt'].qishu==item.qishu&&new_data['buy_tt'].good_id==item.id){
                                // 推送了一条 cb 数据
                                item = {
                                    ...item,
                                    day:new_data['buy_tt'].day,
                                    hours:new_data['buy_tt'].hours,
                                    mins:new_data['buy_tt'].mins,
                                    second:new_data['buy_tt'].second,
                                    timeCount:parseInt(new_data['buy_tt'].day) * 24 * 3600 * 1000 + parseInt(new_data['buy_tt'].hours)  * 3600 * 1000 + parseInt(new_data['buy_tt'].mins)  *60 * 1000 + parseInt(new_data['buy_tt'].second)  * 1000
                                }
                            }
                        }
                    }
                    // return item;
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        }
    },
    data(){
        return {
            newshow:this.$route.query.isnew=="1"&&this.$route.query.is_try_bonus=="0",
            item:[],
            cateproductList:[],
            lang:localStorage.getItem("langData") || 'th',
        }
    },
    mounted(){
        
        // this.active = this.$route.query.active ? this.$route.query.active : this.active;
        // 商品列表 
        // cateproductnop  少请求接口   cateproduct 
        this.$ajax.dashboard()
        .then(res=>{
            this.setdashboard(res.data);
            localStorage.setItem('dashboard',JSON.stringify(res.data))
        })

        this.$ajax.cateproductnop({
        })
        .then(res=>{
            if(res.code==200){
                var list  =  res.data;
                this.cateproductList = list;
                this.item = this.cateproductList[this.activeKey];
            }
        })
    },
    methods:{
        timeEndAction(val){
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    
                    if(val.good_id==item.good_id&&val.qishu==item.qishu){
                        item = {...item, timeend:true} 
                    }
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        },
        changescroll(flag){
            if(flag){
                document.getElementById('scrollbox').classList.add('vux-modal-open');
                }else{
                document.getElementById('scrollbox').classList.remove('vux-modal-open');
            }
        },
        stopSome(e){
            e.preventDefault();
        },
        gotowhere(name){
            this.$router.push({
                name
            })
        },
        ...mapMutations(['changecateproductList']),
        gotoQuestion(){
            this.$refs.play.show = true;
        },
    }
}
</script>